body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  max-width: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}
